import { CircularProgress, Grid, Typography } from '@mui/material';
import { ApplicationLogo } from 'Application/interfaces/item';
import CardWrapper from 'Common/components/CardWrapper';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import useCommonSelector from 'Common/utils/use-selector';
import {
  RiskAssessmentSDLCWidgetItem,
  RiskAssessmentSDLCWidgetItemData,
  RiskAssessmentSeverity,
} from 'Dashboard/interfaces/RiskAssessmentSDLCWidgetItem.model';
import { getFilterState, selectdashboardFilter } from 'Dashboard/store';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { GridType } from 'Risk/store';
import { useEffect, useMemo, useState } from 'react';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import _ from 'lodash';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { NumberFormatHandler } from 'shared/handlers/number-format.handler';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import SeverityBreakdown from 'shared/components/SeverityBreakdown/SeverityBreakdown';
import {
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { serviceAttributes } from 'shared/fixtures/data/dashboard.data';

interface RiskAssessmentCardProps extends RiskAssessmentSDLCWidgetItem {
  isLoading?: boolean;
  widgetData?: any;
  scopeData?: Array<OrganizationNode>;
  groupData?: Array<OrganizationNode>;
}

const navigationHandler = new NavigationHandler();

const numberFormatHandler = new NumberFormatHandler();

export const RiskAssessmentCard: React.FC<RiskAssessmentCardProps> = ({
  title,
  type,
  icon,
  isLoading,
  widgetData,
  findingTypes,
  scopeData,
  groupData,
}) => {
  const [sdlcData, setSdlcData] =
    useState<RiskAssessmentSDLCWidgetItemData | null>();

  const dashboardFilter = useCommonSelector(selectdashboardFilter);

  const filterState = useCommonSelector(getFilterState);

  const hasServiceAttributesFilter = useMemo<boolean>(
    () => {
      for (const attribute of serviceAttributes) {
        if (
          (filterState[attribute] as MultiSelectState)?.selectedOptions?.length
        ) {
          return true;
        }
      }
      return false;
    },
    serviceAttributes.map((attr) => filterState[attr])
  );

  const businessUnitNavigationFilter = useMemo<
    Partial<{ businessUnitId: Array<FilterOption> }>
  >(() => {
    if (dashboardFilter.businessUnitItems?.length === 1) {
      return {
        businessUnitId: dashboardFilter.businessUnitItems,
      };
    }

    if (filterState['businessUnitId']) {
      const typedState = filterState['businessUnitId'] as MultiSelectState;
      const selectedOptions = typedState.selectedOptions;

      return typedState.allSelected
        ? hasServiceAttributesFilter
          ? {
              businessUnitId:
                selectedOptions?.map((selectedOption) => ({
                  id: selectedOption.value,
                  name: selectedOption.label as string,
                })) || [],
            }
          : {}
        : {
            businessUnitId:
              selectedOptions?.map((selectedOption) => ({
                id: selectedOption.value,
                name: selectedOption.label as string,
              })) || [],
          };
    }

    return {};
  }, [filterState['businessUnitId'], dashboardFilter.businessUnitItems]);

  const groupNavigationFilter = useMemo<
    Partial<{ groupId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    if (filterState['groupId']) {
      const typedState = filterState['groupId'] as SingleSelectState;
      const selectedOptions = typedState.selectedOptions;

      return selectedOptions?.length
        ? selectedOptions[0].value !== 'All'
          ? {
              groupId: {
                id: selectedOptions[0].value,
                name: selectedOptions[0]?.label as string,
              },
            }
          : {}
        : {};
    }

    return {};
  }, [filterState['groupId'], businessUnitNavigationFilter]);

  const scopeNavigationFilter = useMemo<
    Partial<{ scopeId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    if (filterState['scopeId']) {
      const typedState = filterState['scopeId'] as SingleSelectState;
      const selectedOptions = typedState.selectedOptions;

      return selectedOptions?.length
        ? selectedOptions[0].value !== 'All'
          ? {
              scopeId: {
                id: selectedOptions[0].value,
                name: selectedOptions[0]?.label as string,
              },
            }
          : {}
        : {};
    }

    return {};
  }, [filterState['scopeId'], businessUnitNavigationFilter]);

  useEffect(() => {
    if (widgetData) {
      setSdlcData(widgetData);
    }
  }, [widgetData, type, isLoading]);

  const renderTooltipContent = (appList?: ApplicationLogo[]) => {
    return (
      <Grid container className="risk-app-tooltip">
        {appList?.map((app) => (
          <Grid item xs={12} key={app.id} className="risk-app-item">
            <OpusImageIcon src={app.logo} className="application-logo" />
            <p className="label-4">{app.name}</p>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderApplicationsList = (applications?: ApplicationLogo[]) => {
    if (!applications?.length)
      return (
        <Grid
          item
          xs={12}
          display="flex"
          gap={1}
          className="applications-list"
        ></Grid>
      );
    return (
      <Grid item xs={12} display="flex" gap={1} className="applications-list">
        {applications?.slice(0, 3).map((item) => (
          <CardWrapper className="application-logo-container" key={item.id}>
            <OpusImageIcon src={item.logo} className="application-logo" />
          </CardWrapper>
        ))}
        {applications?.length > 3 && renderMoreApplicationsButton(applications)}
      </Grid>
    );
  };

  const renderMoreApplicationsButton = (applications: ApplicationLogo[]) => {
    return (
      <CardWrapper className="more-app-button" key={'more-app'}>
        <CommonTooltip
          placement="top"
          title={renderTooltipContent(applications?.slice(3))}
        >
          <p>+{applications?.slice(3).length}</p>
        </CommonTooltip>
      </CardWrapper>
    );
  };

  const renderSeverityList = (severityList?: RiskAssessmentSeverity[]) => {
    if (severityList?.length === 0) return <></>;
    const transformedSeverityData = severityList?.reduce((result, item) => {
      result[item.severity] = item.totalNumber;
      return result;
    }, {} as Partial<Record<FindingSeverity, number>>);
    return (
      <Grid item xs={12} display="flex">
        <SeverityBreakdown
          severityData={transformedSeverityData || {}}
          severityToDisplay={[
            FindingSeverity.HIGH,
            FindingSeverity.CRITICAL,
            FindingSeverity.MEDIUM,
          ]}
        />
      </Grid>
    );
  };

  if (!isLoading && !sdlcData) {
    return null;
  }
  return (
    <CardWrapper className="risk-assessment-dashboard-card">
      <Grid
        container
        className="risk-assessment-dashboard-card-container"
        columnSpacing={{ xs: 2, sm: 4, md: 3 }}
      >
        <Grid item xs={12} display="flex" gap={1} alignItems="center">
          <div className="title-icon">
            <OpusSvgIcon type={icon} />
          </div>
          <div
            className="title"
            onClick={() => {
              let commonFindingTypes =
                dashboardFilter.findingTypes.length > 0
                  ? _.intersection(dashboardFilter.findingTypes, findingTypes)
                  : findingTypes || [];

              if (
                commonFindingTypes.length === 0 &&
                sdlcData?.applications?.length
              ) {
                commonFindingTypes = findingTypes || [];
              }

              if (commonFindingTypes.length) {
                navigationHandler.handleRiskNavigation(GridType.None, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  findingType: commonFindingTypes?.map((findingType) => ({
                    id: findingType,
                    name: findingType,
                  })) as Array<FilterOption>,
                  ...businessUnitNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }
            }}
          >
            <Typography variant="inherit" className="label-1">
              {title}
            </Typography>
          </div>
        </Grid>
        {isLoading ? (
          <Grid item display="flex" textAlign={'center'} xs={12}>
            <CircularProgress size={18} />
          </Grid>
        ) : (
          <>
            {renderApplicationsList(sdlcData?.applications)}
            {renderSeverityList(sdlcData?.severityList)}
          </>
        )}
      </Grid>
    </CardWrapper>
  );
};
