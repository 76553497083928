import authConstants from 'Auth/constants';
import CopyButton from 'Common/components/CopyButton';
import commonConfig from 'Config';
import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import { FunctionComponent, useMemo, useState } from 'react';
import FormInput from 'shared/components/FormInput';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { TabList, ITabListItem } from 'shared/components/TabList/TabList';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

enum AzureIntegrationSubscriptionTab {
  SINGLE = 'Single',
  MULTIPLE = 'Multiple',
}

const azureIntegrationSubscriptionTabs: Array<ITabListItem> = [
  {
    id: AzureIntegrationSubscriptionTab.SINGLE,
    label: AzureIntegrationSubscriptionTab.SINGLE,
  },
  {
    id: AzureIntegrationSubscriptionTab.MULTIPLE,
    label: AzureIntegrationSubscriptionTab.MULTIPLE,
  },
];

interface AzureIntegrationSubscriptionSectionProps extends BaseComponentProps {
  singleSubscriptionProps?: {
    subscriptionId: string;
    value?: string;
    changeHandler: (value: string) => void;
    errorMessage?: string;
  };
  template?: ConnectionTemplateItem;
}

export const AzureIntegrationSubscriptionSection: FunctionComponent<
  AzureIntegrationSubscriptionSectionProps
> = ({ singleSubscriptionProps, template }) => {
  const [activeTabId, setActiveTabId] =
    useState<AzureIntegrationSubscriptionTab>(
      AzureIntegrationSubscriptionTab.SINGLE
    );

  const [managmentGroupId, setManagmentGroupId] = useState<string>(
    'management-group-id'
  );

  const singleDeploymentLink = useMemo(() => {
    const deploymentLink = template?.definition?.props?.deployLink || '';
    return deploymentLink;
  }, [template]);

  const downloadCommand = useMemo(() => {
    const scriptDownloadCommand =
      template?.definition?.props?.multiDeploy?.scriptDownloadCommand || '';
    return `wget ${scriptDownloadCommand}`;
  }, [template]);

  const dowloadPermissionCommand = useMemo(() => {
    const scriptDownloadCommand =
      `./${template?.definition?.props?.multiDeploy?.scriptName}` || '';

    return `chmod +x ${scriptDownloadCommand}`;
  }, [template]);

  const deploymentCommand = useMemo(() => {
    const scriptName =
      template?.definition?.props?.multiDeploy?.scriptName || '';

    const scriptArmTemplateUri =
      template?.definition?.props?.multiDeploy?.scriptArmTemplateUri || '';

    const accessToken = localStorage.getItem(authConstants.authAccessToken);

    const gwUrl = commonConfig.globalGwBaseUrl;

    return `./${scriptName} ${managmentGroupId} ${gwUrl} ${scriptArmTemplateUri} ${accessToken}`;
  }, [template, managmentGroupId]);

  const renderTabContent = () => {
    switch (activeTabId) {
      case AzureIntegrationSubscriptionTab.SINGLE:
        return (
          <div className="azure-integration-subscription-single">
            <FormInput
              label="Fill in the object ID in the arm template and take out your subscription ID"
              name="azureSubscriptionId"
              placeholder="Subscription ID"
              value={singleSubscriptionProps?.value}
              onChange={singleSubscriptionProps?.changeHandler}
              errorMessage={singleSubscriptionProps?.errorMessage}
              components={{
                TitleRow: (
                  <div className="cloud-integration-input-title">
                    <a href={singleDeploymentLink} target="_blank">
                      Click here
                    </a>
                    <span>to connect to your Azure subscription</span>
                  </div>
                ),
              }}
            />
          </div>
        );
      case AzureIntegrationSubscriptionTab.MULTIPLE:
        return (
          <div className="azure-integration-subscription-multiple">
            <h3 className="azure-integration-subscription-multiple-title">
              Use Azure CLI for deploying across a management group
            </h3>
            <InputLabelWrapper label="Download bash script using Azure cloud shell">
              <div className="azure-integration-subscription-script-download-input">
                <input
                  className="text-field-1"
                  disabled
                  value={downloadCommand}
                ></input>
                <CopyButton textToCopy={downloadCommand} />
              </div>
            </InputLabelWrapper>

            <InputLabelWrapper
              label="Give the downloaded script execute permissions
"
            >
              <div className="azure-integration-subscription-script-download-input">
                <input
                  className="text-field-1"
                  disabled
                  value={dowloadPermissionCommand}
                ></input>
                <CopyButton textToCopy={dowloadPermissionCommand} />
              </div>
            </InputLabelWrapper>

            <InputLabelWrapper label="Enter your management group id">
              <div className="azure-integration-subscription-management-group-id-input">
                <input
                  className="text-field-1"
                  value={managmentGroupId}
                  onChange={(event) => {
                    const newValue = event.target.value;

                    setManagmentGroupId(newValue);
                  }}
                ></input>
              </div>
            </InputLabelWrapper>

            <InputLabelWrapper label="Run the script in your Azure cloud shell">
              <div className="azure-integration-subscription-script-input">
                <textarea
                  value={deploymentCommand}
                  className="text-area-field-1"
                  rows={7}
                  disabled
                ></textarea>
                <CopyButton textToCopy={deploymentCommand} />
              </div>
            </InputLabelWrapper>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="azure-integration-subscription-section-container">
      <TabList
        activeItemId={activeTabId}
        items={azureIntegrationSubscriptionTabs}
        isUnderlined
        onSelect={(id: AzureIntegrationSubscriptionTab) => {
          setActiveTabId(id);
        }}
      />
      <div className="azure-integration-subscription-section-content">
        {renderTabContent()}
      </div>
    </div>
  );
};
