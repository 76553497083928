import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import TabList from 'shared/components/TabList';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import BusinessUnitList from '../BusinessUnitList';
import CloudWorkspacesList from '../CloudWorkspacesList';
import SlaList from '../SlaList';
import TeamsList from '../TeamsList';
import { NotificationRuleList } from '../NotificationRuleList/NotificationRuleList';
import OrganizationMain from 'Organization';
import UsersList from '../UsersList';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import { ITabListItem } from '../../../shared/components/TabList/TabList';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import { ReportRuleList } from '../ReportRuleList';
import RiskCustomization from '../RiskCustomization';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { resetSearchParams } from 'Settings/store';

export const SettingsNavPage = () => {
  const { t: translation } = useTranslation();
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const userPermissions = usePermissions();
  const activeTabId = useMemo<SettingsTabPanel>(() => {
    return urlSearchParams.activeTabId as SettingsTabPanel;
  }, [urlSearchParams]);

  const dispatch = useCommonDispatch();

  const tabListItems: ITabListItem[] = [
    {
      id: SettingsTabPanel.SERVICE,
      label: translation(`settings.tabs.services`),
      permissions: [ApplicationPermission.SETTINGS_BUS_VIEW],
    },
    {
      id: SettingsTabPanel.CLOUD_WORKSPACES,
      label: translation(`settings.tabs.workspaces`),
      permissions: [ApplicationPermission.SETTINGS_WORKSPACES_VIEW],
    },
    {
      id: SettingsTabPanel.SLA,
      label: translation(`settings.tabs.sla`),
      permissions: [ApplicationPermission.SETTINGS_SLA_VIEW],
    },
    {
      id: SettingsTabPanel.TEAMS,
      label: translation(`settings.tabs.teams`),
      permissions: [ApplicationPermission.SETTINGS_TEAMS_VIEW],
    },
    {
      id: SettingsTabPanel.AUTOMATION_RULES,
      label: translation(`settings.tabs.automationRules`),
      permissions: [ApplicationPermission.SETTINGS_NOTIFICATION_RULES_VIEW],
    },
    {
      id: SettingsTabPanel.ORGANIZATION,
      label: translation(`settings.tabs.organization`),
      permissions: [ApplicationPermission.SETTINGS_ORGANIZATION_VIEW],
    },
    {
      id: SettingsTabPanel.USERS,
      label: translation(`settings.tabs.users`),
      permissions: [ApplicationPermission.SETTINGS_USERS_VIEW],
    },
    {
      id: SettingsTabPanel.REPORT_RULES,
      label: translation(`settings.tabs.reportRules`),
      permissions: [ApplicationPermission.SETTINGS_REPORTS_RULES_VIEW],
    },
    {
      id: SettingsTabPanel.RISK_CUSTOMIZATION,
      label: translation(`settings.tabs.riskCustomization`),
      permissions: [ApplicationPermission.SETTINGS_RISK_SCORE_READ],
    },
  ];

  const renderTableForTab = () => {
    const tab = tabListItems.find((tab) => tab.id === activeTabId);
    const hasPermission = (tab?.permissions || []).every((permission) =>
      userPermissions.includes(permission)
    );
    if (!hasPermission) return <ForbiddenPage />;

    switch (activeTabId) {
      case SettingsTabPanel.SERVICE:
        return <BusinessUnitList />;
      case SettingsTabPanel.CLOUD_WORKSPACES:
        return <CloudWorkspacesList />;
      case SettingsTabPanel.SLA:
        return <SlaList />;
      case SettingsTabPanel.TEAMS:
        return <TeamsList />;
      case SettingsTabPanel.AUTOMATION_RULES:
        return <NotificationRuleList />;
      case SettingsTabPanel.ORGANIZATION:
        return <OrganizationMain />;
      case SettingsTabPanel.USERS:
        return <UsersList />;
      case SettingsTabPanel.REPORT_RULES:
        return <ReportRuleList />;
      case SettingsTabPanel.RISK_CUSTOMIZATION:
        return <RiskCustomization />;
      default:
        if (!urlSearchParams.activeTabId) {
          setUrlSearchParams({
            activeTabId: SettingsTabPanel.SERVICE,
          });
        }
        return <BusinessUnitList />;
    }
  };

  return (
    <Box className="settings-page-body-container">
      <Stack className="settings-page-body-control-row">
        <TabList
          items={tabListItems}
          activeItemId={activeTabId}
          onSelect={(activeItemId: SettingsTabPanel) => {
            setUrlSearchParams({
              activeTabId: activeItemId,
            });

            dispatch(resetSearchParams());
          }}
        />
      </Stack>

      <div className="settings-page-body-table">{renderTableForTab()}</div>
    </Box>
  );
};
