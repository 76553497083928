import { GridType } from 'Risk/store';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ApplicationPermission } from '../../enums/permission.enum';
import { usePermissions } from '../../hooks/usePermissionActions';
import { UserDetailsService } from 'shared/services/users/user-details.service';
import { useAuthUser } from '@frontegg/react';
import { Role } from 'Auth/store/api';
import { SystemFeatureFlag } from '../EntitledContent/EntitledContent';
import TabListItem from '../TabListItem';

export interface ITabListItem {
  id: any;
  label?: string;
  iconUrl?: string;
  props?: any;
  permissions?: ApplicationPermission[];
  featureFlag?: SystemFeatureFlag;
  roles?: Role[];
}

interface TabListProps extends BaseComponentProps {
  items: Array<ITabListItem>;
  onSelect?: (id: any) => void;
  activeItemId: any;
  label?: string | JSX.Element;
  isUnderlined?: boolean;
}

export const TabList: FunctionComponent<TabListProps> = ({
  items,
  onSelect,
  activeItemId,
  label,
  isUnderlined = false,
}) => {
  const userPermissions = usePermissions();
  const user = useAuthUser();

  const onHandleSelect = (id: GridType) => {
    onSelect && onSelect(id);
  };

  return (
    <div className={`tab-list-container`}>
      {label ? (
        <div className="tab-list-container-label">{label}</div>
      ) : (
        <div></div>
      )}
      <div className={`tab-list-body ${isUnderlined && 'underlined'}`}>
        {items.map((item: ITabListItem) => (
          <TabListItem
            {...item}
            onSelect={onHandleSelect}
            selected={activeItemId === item.id}
            user={user}
            userPermissions={userPermissions}
          />
        ))}
      </div>
    </div>
  );
};
