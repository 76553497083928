import { Button } from '@mui/material';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';
import CommonIconMui from 'Common/components/IconMui/Icon';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { AuthorizedContent } from '@frontegg/react';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { TabList, ITabListItem } from 'shared/components/TabList/TabList';
import { WorkflowTriggerDto } from '../Layout/Layout';

interface DiagramNavbarProps {
  children?: any;
  saveWorkflow: (newName: string) => void;
  mode: LAYOUT_MODE;
  workflowData: any;
  isFetchingWorkflowData?: boolean;
  tabListItems?: Array<ITabListItem>;
  activeTabId?: string;
  onSelectActiveTab?: (tabId: string) => void;
}

export default function DiagramNavbar({
  saveWorkflow,
  isFetchingWorkflowData,
  mode,
  workflowData,
  activeTabId,
  tabListItems,
  onSelectActiveTab,
}: DiagramNavbarProps) {
  const { t: translation } = useTranslation();

  const [workflowName, setWorkflowName] = useState<string>(
    workflowData?.name || ''
  );

  const [workflowNameValidationError, setWorkflowNameValidationError] =
    useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (workflowData?.name) {
      setWorkflowName(workflowData.name);
    }
  }, [workflowData]);

  function save() {
    if (workflowName.length) {
      saveWorkflow(workflowName);
    } else {
      setWorkflowNameValidationError(true);
    }
  }

  const renderTabList = () => {
    if (workflowData && activeTabId && tabListItems && onSelectActiveTab) {
      return (
        <TabList
          activeItemId={activeTabId}
          items={tabListItems}
          onSelect={onSelectActiveTab}
          isUnderlined
        />
      );
    }

    return <></>;
  };

  return (
    <div className="workflow-builder-header">
      <div className="workflow-builder-header-text-row">
        {isFetchingWorkflowData && (
          <h5 className="workflow-builder-header-text">
            <FormattedMessage
              id="flows.details.editYourWorkflow"
              defaultMessage="Edit your workflow"
            />
          </h5>
        )}
        {workflowData && mode === LAYOUT_MODE.VIEW && (
          <h5 className="workflow-builder-header-text">{workflowData.name}</h5>
        )}
        {!isFetchingWorkflowData && mode === LAYOUT_MODE.EDIT && (
          <input
            placeholder={translation(`flows.details.typeWorkflowName`)}
            defaultValue={workflowData?.name}
            className={`text-field-1 workflow-builder-header-text-input ${
              workflowNameValidationError ? 'input-validation-error-1' : ''
            }`}
            required
            onChange={(event) => {
              const inputValue = event.target.value;
              setWorkflowNameValidationError(false);
              setWorkflowName(inputValue);
            }}
          />
        )}
      </div>

      {renderTabList()}

      <div className="workflow-builder-header-button-row">
        <Button
          className="opus-secondary-button workflow-builder-header-button-close"
          onClick={() => navigate(`/flows`)}
        >
          <FormattedMessage
            capitalize
            id="common.close"
            defaultMessage="Close"
          />
        </Button>
        <AuthorizedContent
          requiredPermissions={[ApplicationPermission.WORKFLOWS_WRITE]}
        >
          <Button
            className="opus-primary-button workflow-builder-header-button-save"
            onClick={save}
          >
            <OpusSvgIcon
              type={
                mode === LAYOUT_MODE.VIEW
                  ? SVG_ICON_TYPES.EDIT_ICON
                  : SVG_ICON_TYPES.FLOPPY_DISK_ICON
              }
            />
            <FormattedMessage
              capitalize
              id={mode === LAYOUT_MODE.VIEW ? 'common.edit' : 'common.save'}
              defaultMessage={mode === LAYOUT_MODE.VIEW ? 'Edit' : 'Save'}
            />
          </Button>
        </AuthorizedContent>
      </div>
    </div>
  );
}
