import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ITabListItem } from '../TabList/TabList';
import { User } from 'shared/models/data/authenticated-user.model';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { UserDetailsService } from 'shared/services/users/user-details.service';
import { useFeatureEntitlements } from '@frontegg/react';

interface TabListItemProps extends BaseComponentProps, ITabListItem {
  user: User;
  userPermissions: Array<ApplicationPermission>;
  selected: boolean;
  onSelect: (id: any) => void;
}

const userDetailsService = new UserDetailsService();

export const TabListItem: FunctionComponent<TabListItemProps> = ({
  roles,
  id,
  featureFlag,
  iconUrl,
  label,
  permissions,
  props,
  user,
  userPermissions,
  selected,
  onSelect,
}) => {
  const hasAllRoles = (roles || []).every((role) =>
    userDetailsService.hasRoleWithId(user, role)
  );

  const { isEntitled } = useFeatureEntitlements(featureFlag || '');

  return hasAllRoles && (!featureFlag || (featureFlag && isEntitled)) ? (
    <button
      key={id}
      className={`tab-list-item ${selected ? 'tab-list-item-active' : ''}`}
      disabled={
        !(permissions || []).every((permission) =>
          userPermissions.includes(permission)
        )
      }
      type="button"
      onClick={() => onSelect(id)}
    >
      <div className="tab-list-item-content">
        {iconUrl && (
          <img src={iconUrl} alt={label} className="tab-list-item-icon"></img>
        )}
        {label && <p>{label}</p>}
      </div>
    </button>
  ) : null;
};
