import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { insightsData } from 'Dashboard/interfaces/InsightTypes.enum';
import { GridType } from 'Risk/store';
import { NavigationHandler } from 'shared/handlers/navigation.handler';

export interface WidgetBlockProps {
  type: PostureWidgetAnalyticsType;
  data: any;
  navigationFilters: any;
}
const navigationHandler = new NavigationHandler();

export const WidgetBlock: FunctionComponent<WidgetBlockProps> = ({
  type,
  data,
  navigationFilters = {},
}) => {
  const { t: translation } = useTranslation();

  const insightData = useMemo(
    () => insightsData.find((item) => item.type === type),
    [type]
  );

  const criticality = insightData?.criticality?.toLowerCase() || 'unknown';
  const title = insightData?.title || '';
  const description = insightData?.description || '';
  const findingsCount = data?.value || 0;

  if (findingsCount === 0) return <></>;
  return (
    <div
      className="insight-block"
      onClick={() => {
        navigationHandler.handleRiskNavigation(
          GridType.None,
          { ...data.filter, ...navigationFilters },
          true
        );
      }}
    >
      <div className={`icon ${criticality}`}>
        <OpusSvgIcon type={SVG_ICON_TYPES.LIGHTBULB_ICON} />
      </div>
      <div className="insight-details">
        <div className="title">
          <p className="label-5">{title}</p>
          <div className="finding-count">
            {findingsCount} {translation('common.findings', 'Findings')}
          </div>
          <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_RIGHT_ICON} />
        </div>
        <div className="description">
          <p className="body-1">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default WidgetBlock;
