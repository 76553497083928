import { Typography, Grid } from '@mui/material';
import { useFetchUsersMutation, UserInfo } from 'Auth/store/api';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefetch } from 'Risk/context/RefetchContext';
import { FindingBulkActionTypeEnum } from 'Risk/interfaces/FindingBulkActionType.enum';
import { useBulkFindingActionByTypeMutation } from 'Risk/store/api';
import DropdownPicker from 'shared/components/DropdownPicker';
import OpusAvatarIcon from 'shared/components/IconComponents/OpusAvatarIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { StatusItemsList } from 'shared/fixtures/data/status-items-list';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  ActionMenuModalConfiguration,
  FindingActionsFormValues,
} from 'shared/models/action-menu-modal-configuration';
import { LabelFilterState } from 'shared/models/data/data-filter.model';
import { StatusItem } from 'shared/models/data/status-item.mode';

interface ActionsMenuModalProps {
  handleModalClose: () => void;
  configuration: ActionMenuModalConfiguration;
  selection: any;
  tagFilter: any;
  labelFilter: LabelFilterState;
  vulnerabilityFilter: any;
  genericFilter: any;
}

export const ActionsMenuModal: FunctionComponent<ActionsMenuModalProps> = ({
  handleModalClose,
  configuration,
  selection,
  genericFilter,
  vulnerabilityFilter,
  tagFilter,
  labelFilter,
}) => {
  const { t: translation } = useTranslation();
  const isStatusChangeModal: boolean =
    configuration.actionType === FindingBulkActionTypeEnum.CHANGE_STATUS;
  const isAssigneeChangeModal: boolean =
    configuration.actionType === FindingBulkActionTypeEnum.ASSIGNEE_CHANGE;
  const formElement = useRef<HTMLFormElement>(null);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [fetchUsers, { data: userList }] = useFetchUsersMutation();
  const { refetchGridData } = useRefetch();
  const [
    bulkChangeFindings,
    {
      isLoading: isLoadingBulkChange,
      isError: bulkChangeFailed,
      isSuccess: bulkChangeSuccess,
      reset: resetBulkChanges,
    },
  ] = useBulkFindingActionByTypeMutation();

  const [formValues, setFormValues] = useState<FindingActionsFormValues>({
    assigneeId: '',
    status: '',
  });
  const onSubmit = () => {
    let payload = {
      body: {
        findings: [],
        selectionProps: selection,
        newAssigneeId: formValues['assigneeId'],
        status: formValues['status'],
        filter: {
          filter: genericFilter,
          vulnerabilityFilter: vulnerabilityFilter,
          tagFilter: tagFilter,
          labelFilter: labelFilter,
        },
      },
      type: '',
    };
    if (isAssigneeChangeModal) {
      delete payload.body['status'];
      payload.type = FindingBulkActionTypeEnum.ASSIGNEE_CHANGE;
    }
    if (isStatusChangeModal) {
      delete payload.body['newAssigneeId'];
      payload.type = FindingBulkActionTypeEnum.CHANGE_STATUS;
    }
    bulkChangeFindings(payload);
  };

  useEffect(() => {
    if (bulkChangeSuccess) {
      handleCloseModal();
      refetchGridData();
    }
  }, [bulkChangeSuccess]);

  const handleCloseModal = () => {
    handleModalClose();
    setFormValues({ assigneeId: '', status: '' });
    resetBulkChanges();
    setWarningMessage('');
  };

  useEffect(() => {
    if (bulkChangeFailed) {
      setWarningMessage(
        isAssigneeChangeModal
          ? translation('findings.action.uanbleToChange', {
              param: translation('findings.action.assignee'),
            })
          : translation('findings.action.uanbleToChange', {
              param: translation('findings.action.status'),
            })
      );
    }
  }, [bulkChangeFailed]);

  useEffect(() => {
    if (configuration.isOpen && isAssigneeChangeModal) {
      fetchUsers();
    }
  }, [configuration, isAssigneeChangeModal]);

  const setFormValue = (
    inputName: keyof FindingActionsFormValues,
    values: string
  ) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [inputName]: values,
    }));
  };

  const renderButtonContent = () => {
    const selectedData = isAssigneeChangeModal
      ? userList?.find((user) => user.id === formValues['assigneeId'])
      : StatusItemsList.find((status) => status.id === formValues['status']);

    const placeholderTranslationKey = isAssigneeChangeModal
      ? 'findings.action.selectAssignee'
      : 'findings.action.selectStatus';

    if (!selectedData) {
      return (
        <Typography className="placeholder">
          {translation(placeholderTranslationKey)}
        </Typography>
      );
    }

    return (
      <Grid display={'flex'} alignItems="center">
        {isAssigneeChangeModal ? (
          <>
            <OpusAvatarIcon
              src={(selectedData as UserInfo)?.profilePictureUrl}
              className="organization-form-image-container"
            />
            <Typography variant="inherit" className="body-1" ml={2}>
              {(selectedData as UserInfo)?.name}
            </Typography>
          </>
        ) : (
          <>
            <OpusSvgIcon
              title={(selectedData as StatusItem)?.id}
              className={'status-icon'}
              type={
                (selectedData as StatusItem)?.icon || SVG_ICON_TYPES.CLOCK_ICON
              }
            />
            <Typography variant="inherit" className="body-1" ml={2}>
              {(selectedData as StatusItem)?.id}
            </Typography>
          </>
        )}
      </Grid>
    );
  };

  const renderDropdownItem = (item: any) => {
    if (isAssigneeChangeModal) {
      return (
        <>
          <OpusAvatarIcon
            src={item?.profilePictureUrl}
            className="organization-form-image-container "
          />
          <Typography className={'menu-item-text'}>{item.name}</Typography>
        </>
      );
    } else {
      return (
        <>
          <OpusSvgIcon
            title={item.id}
            className={'status-icon'}
            type={item.icon}
          />
          <Typography className="menu-item-text">{item.id}</Typography>
        </>
      );
    }
  };

  const renderForm = () => {
    const dropdownProps = {
      displayFullWidthPopover: true,
      listItems:
        configuration?.actionType === FindingBulkActionTypeEnum.ASSIGNEE_CHANGE
          ? userList
          : StatusItemsList.filter((statusItem) => !statusItem.hidden),
      readonly: false,
      searchMode:
        configuration?.actionType === FindingBulkActionTypeEnum.ASSIGNEE_CHANGE,
      getSearchProperty: (item: any) => item?.name,
      renderButtonContent: renderButtonContent,
      renderItem: (item: any) => renderDropdownItem(item),
      onListItemClick: (item: any) => {
        const key =
          configuration?.actionType ===
          FindingBulkActionTypeEnum.ASSIGNEE_CHANGE
            ? 'assigneeId'
            : 'status';
        setFormValue(key, item.id as string);
      },
    };
    return (
      <InputLabelWrapper label="">
        <DropdownPicker {...dropdownProps} />
      </InputLabelWrapper>
    );
  };

  const getFormValidation = () => {
    switch (configuration.actionType) {
      case FindingBulkActionTypeEnum.ASSIGNEE_CHANGE:
        return !formValues['assigneeId'];
      case FindingBulkActionTypeEnum.CHANGE_STATUS:
        return !formValues['status'];
      default:
        break;
    }
  };

  return (
    <CommonDialog
      classes="common-dialog-actions"
      open={configuration.isOpen}
      onClose={() => {
        handleCloseModal();
      }}
      loadingButtonText={translation('common.saving')}
      title={configuration.title}
      isOnSubmitDisabled={getFormValidation()}
      icon={configuration.icon}
      isLoading={isLoadingBulkChange}
      saveButtonText={translation('common.save')}
      buttonRequiredPermissions={[]}
      onSave={() => {
        onSubmit();
      }}
      closeButtonText={translation('common.cancel')}
    >
      <div className="common-form-container findings-bulk-actions-form">
        <form ref={formElement} onSubmit={onSubmit}>
          <Grid container minWidth={'500px'} rowGap={3}>
            <Grid item xs={12}>
              {renderForm()}
            </Grid>
            {bulkChangeFailed && (
              <Grid item xs={12} className="bulk-changes-action-failed ">
                <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
                <Typography variant="inherit" className="body-1">
                  {warningMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </CommonDialog>
  );
};
export default ActionsMenuModal;
