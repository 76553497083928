import { createContext, useContext, useState, useCallback } from 'react';

interface RefetchContextProps {
  refetchGridData: () => void;
  refetchTrigger: number;
  showSpinner: boolean;
}

export const RefetchContext = createContext<RefetchContextProps | null>(null);

export const RefetchProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const refetchGridData = useCallback(() => {
    setShowSpinner(true);

    setTimeout(() => {
      setRefetchTrigger((prev) => prev + 1);
      setShowSpinner(false);
    }, 2000);
  }, []);

  return (
    <RefetchContext.Provider
      value={{ refetchGridData, refetchTrigger, showSpinner }}
    >
      {children}
    </RefetchContext.Provider>
  );
};

export const useRefetch = () => {
  const context = useContext(RefetchContext);
  if (!context) {
    throw new Error('useRefetch must be used within a RefetchProvider');
  }
  return context;
};
